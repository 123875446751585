import React, { useEffect }  from 'react';
import styled from 'styled-components';
import * as s from "./styles/globalStyles";
import Footer from './Footer';
import './styles/content.css';
import EbayListing from './EbayListing';

const bg = '/bg/bg0.png';

const Background = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.section`
  text-align: left;
  margin: 20px;
  line-height: 1.2;
  color: var(--primary-text);
  font-size: 18px;

  h1, h2, h3, h4, h5, h6 {
  }

  ul {
    list-style-type: none;
    padding-left: 20px;
    line-height: 1.5;
  }

  strong {
    font-weight: bold;
  }

  p {
    margin-bottom: 15px;
  }
`;

const Core = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Background>
    <div className={"container"}>
      <Content>
      <h2 className={"header"}>Print at home</h2>
      <p>Pick your Science!</p>
      <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}> {/* Wrap the links in a flex container */}
          <div>
            <p> G̵͍͐ <a href="https://drive.google.com/file/d/1kHQHU_VyZfc64uqPWkz3SSB-kcVk-ZZ8/view?usp=sharing" style={{ color: '#DAA520' }}>Glitch-Deck</a></p> 
            <p> ☉ <a href="https://drive.google.com/file/d/1RK5WgcKqQzMLy2d3lK4LRFzUZe2CjH4k/view?usp=sharing" style={{ color: '#DAA520' }}>Gravity-Deck</a></p> 
          </div>
          <div> 
            <p> ⚛ <a href="https://drive.google.com/file/d/181tLBq83wYAgu1OHailmAHz2f-JfPy6K/view?usp=sharing" style={{ color: '#DAA520' }}>Quantum-Deck</a></p> 
            <p> ☢ <a href="https://drive.google.com/file/d/1NoD9ksUkfegwIrZMSvQ4zPTRTX5eLN3j/view?usp=sharing" style={{ color: '#DAA520' }}>Atomic-Deck</a></p> 
          </div>
          <div>
            <p> 🌐 <a href="https://drive.google.com/file/d/1sGKoKaci_THIjXOLi-YAzZ6VY2Qc10t3/view?usp=sharing" style={{ color: '#DAA520' }}>Planet-Deck</a></p> 
            <p> ⚝ <a href="https://drive.google.com/file/d/1rQi29SZjN0gdI5PzQSyTLtZRLIWLDJHd/view?usp=sharing" style={{ color: '#DAA520' }}>Alchemy-Deck</a></p> 
          </div>
          <div>
            <p> ☣️ <a href="https://drive.google.com/file/d/1xMV_NvQHXXRPGLKqMXYTen07l0yOsY5y/view?usp=sharing" style={{ color: '#DAA520' }}>Life-Deck</a></p> 
            <p> 👁 <a href="https://drive.google.com/file/d/1M2_M6vE6d4dCO_M2MQMBJptdstvbjc11/view?usp=sharing" style={{ color: '#DAA520' }}>Psychic-Deck</a></p> 
          </div>
      </div>
      <s.SpacerMedium />
      <h2 className={"header"}>Official prints</h2>
      {/*<p>Consider claiming Digitals for print: <Link to="/app" >Buy Digital Packs</Link>  </p>*/}
      <div>
            <EbayListing
                title="🧑 Single, $19.99"
                description="1 deck, 34 cards from any faction."
                url="https://www.ebay.com/itm/134650408158"
            />
            <EbayListing
                title="🧑‍🤝‍🧑 Pair, $29.99"
                description="2 decks, 68 cards from 2 factions."
                url="https://www.ebay.com/itm/134650431161"
            />
            <EbayListing
                title="👨‍👩‍👧‍👦 Full Set, $119.99"
                description="8 decks, 272 cards from all factions."
                url="https://www.ebay.com/itm/134820734419"
            />
      </div>
      </Content>
    </div>
    </Background>
    <Footer />
    </>
  );
};

export default Core;

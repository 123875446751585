import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import styled from 'styled-components';
import styles from './styles/Gallery.module.css';
import Footer from './Footer';
import { db, auth } from './firebaseConfig';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';

const bg = '/bg/bg3.png';

const Background = styled.div`
background-image: url(${bg});
background-size: cover;
background-position: center;
min-height: 100vh;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;

const Content = styled.section`
  text-align: left;
  margin: 20px;
  line-height: 1.2;
  color: var(--primary-text);
  font-size: 16px;
`;

const SummaryContainer = styled.div`
  margin: 20px 0;
  padding: 15px;
  background-color: #333;
  color: white;
  border-radius: 5px;
`;

const SuccessMessageContainer = styled.div`
  background-color: #4caf50;
  color: white;
  padding: 15px;
  z-index: 1000;
  border-radius: 5px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 10px 0;
`;

const Button = styled.button`
  background: #DAA520;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #c69518;
  }

  a {
    color: white;
    text-decoration: none;
  }
`;

const FormContainer = styled.div`
  margin: 20px 0;
  padding: 15px;
  background-color: #222;
  color: white;
  border-radius: 5px;

  input, textarea, select {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #555;
    background-color: #444;
    color: white;
  }
`;

const LoadingMessage = styled.div`
  color: white;
  font-size: 18px;
  text-align: center;
  margin: 20px 0;
`;

const fetchBasket = async (userId) => {
  const response = await fetch(`/api/basket?user_id=${userId}`);
  return response.json();
};

const clearBasket = async (userId) => {
  await fetch('/api/basket_clear', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ user_id: userId }),
  });
};

const priceTiers = [
  { maxItems: 18, price: 9 },
  { maxItems: 36, price: 12.99 },
  { maxItems: 55, price: 14.99 },
  { maxItems: 72, price: 18 },
  { maxItems: 90, price: 22.99 },
  { maxItems: 108, price: 26.99 },
  { maxItems: 126, price: 30.99 },
  { maxItems: 144, price: 34.99 },
  { maxItems: 162, price: 38.99 },
  { maxItems: 180, price: 42.99 },
  { maxItems: 198, price: 46.99 },
  { maxItems: 216, price: 50.99 },
  { maxItems: 234, price: 54.99 },
  { maxItems: 396, price: 83.99 },
  { maxItems: 504, price: 103 },
  { maxItems: 612, price: 122.99 },
];

const calculateTotalCost = (numItems) => {
  const basePrice = priceTiers.find((tier) => numItems <= tier.maxItems)?.price;
  if (basePrice !== undefined) return basePrice;

  const maxTier = priceTiers[priceTiers.length - 1];
  const remainder = numItems - maxTier.maxItems;
  const additionalPrice = calculateTotalCost(remainder);

  return maxTier.price + additionalPrice;
};

const calculateShippingCost = (numItems) => {
  const shippingTiers = [
    { maxItems: 36, price: 9.99 },
    { maxItems: 90, price: 12.99 },
    { maxItems: 144, price: 15.99 },
    { maxItems: 198, price: 18.99 },
    { maxItems: 306, price: 24.99 },
    { maxItems: 414, price: 34.99 },
    { maxItems: 515, price: 44.99 },
    { maxItems: Infinity, price: 54.99 },
  ];

  return shippingTiers.find((tier) => numItems <= tier.maxItems)?.price;
};

const countries = [
  "Austria", "Belgium", "Brazil", "Bulgaria", "Canada", "China", "Croatia", "Cyprus",
  "Czech Republic", "Denmark", "Estonia", "Finland", "France", "Germany", "Greece", 
  "Hungary", "Hong Kong", "Iceland", "India", "Ireland", "Italy", "Israel", "Indonesia",
  "Japan", "Latvia", "Lithuania", "Luxembourg", 
  "Macedonia", "Malta", "Mexico", "Montenegro", "Netherlands", "Norway", "Poland", 
  "Portugal", "Romania", "Russia", "Slovakia", "Slovenia", "Spain", "Sweden", 
  "Switzerland", "Taiwan", "Turkey", "UAE", "United Kingdom", "United States", "Vietnam"
];

const Checkout = () => {
  const [basket, setBasket] = useState([]);
  const [maxCards, setMaxCards] = useState(0);
  const [total, setTotal] = useState(0);
  const [shippingCost, setShipping] = useState(0);
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [shippingAddress, setShippingAddress] = useState({
    firstName: '',
    lastName: '',
    streetAddress: '',
    city: '',
    postalCode: '',
    country: ''
  });

  useEffect(() => {
    const calculateMaxCards = () => {
      if (basket.length === 0) return;
      const nextTier = priceTiers.find(tier => tier.maxItems > basket.length);
      setMaxCards(nextTier ? nextTier.maxItems : priceTiers[priceTiers.length - 1].maxItems);
    };

    calculateMaxCards();
  }, [basket]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      //console.log("Auth state changed. Current user:", currentUser);
      setUser(currentUser);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchBasketData = async () => {
      if (user && user.uid) {
        try {
          const basketItems = await fetchBasket(user.uid);
          setBasket(basketItems);
          const numItems = basketItems.length;
          const totalCost = calculateTotalCost(numItems);
          setTotal(totalCost);
          const totalShipping = calculateShippingCost(numItems);
          setShipping(totalShipping);
        } catch (error) {
          console.error('Error fetching basket:', error);
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    fetchBasketData();
  }, [user]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const status = queryParams.get('status');
    const sid = queryParams.get('session_id');

    if (status === 'success' && sid) {
      setSessionId(sid);
    } else if (status === 'cancel') {
      setSuccessMessage('Payment was canceled. Please try again.');
    }
  }, []);

  useEffect(() => {
    if (user && sessionId) {
      checkPaymentStatus(sessionId);
    }
  }, [user, sessionId]);

  const saveOrder = async (sessionId) => {
    if (!user || !user.uid) {
      console.error("No user found when trying to save order");
      setSuccessMessage("Error: User not authenticated. Please log in and try again.");
      return;
    }

    setSuccessMessage("Loading: please wait.");

    try {
      //console.log("Attempting to save order to Firebase...");
      // Fetch basket data to ensure it's current
      const basketItems = await fetchBasket(user.uid);
      const numItems = basketItems.length;
      const totalCost = calculateTotalCost(numItems);
      const totalShipping = calculateShippingCost(numItems);

      const orderData = {
        userId: user.uid,
        sessionId: sessionId,
        items: basketItems,
        total: totalCost + totalShipping,
        status: 'paid',
        createdAt: serverTimestamp(),
        email: user.email,
        shippingAddress: shippingAddress
      };
  
      //console.log("Order data:", orderData);
  
      const orderRef = await addDoc(collection(db, 'orders'), orderData);
      //console.log("Order saved with ID: ", orderRef.id);

      // Trigger email notification
      const response = await fetch('/api/notify-order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          orderId: orderRef.id,
          userId: user.uid,
          items: orderData.items,
          total: orderData.total,
          email: user.email,
          shippingAddress: shippingAddress,
          status: orderData.status
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      //console.log("Email notification result:", result);
      setSuccessMessage('Order processed successfully!');
    } catch (error) {
      console.error("Error in saveOrder function:", error);
      setSuccessMessage(`ERROR processing order: ${error.message}`);
    }
  };

  const checkPaymentStatus = async (sessionId) => {
    if (!user || !user.uid) {
      console.log("User not authenticated yet, waiting...");
      return;
    }

    try {
      //console.log("Checking payment status for session:", sessionId);
      setIsLoading(true)
      const response = await fetch(`/api/payment-status?sessionId=${sessionId}`);
      const data = await response.json();
      //console.log("Payment status response:", data);
      if (data.success) {
        await saveOrder(sessionId);
        await clearBasket(user.uid);
        setBasket([]);
        setTotal(0);
        setIsLoading(false)
        window.location.href = '/orders';
      } else {
        setSuccessMessage("Payment not confirmed. Please contact support.");
        setIsLoading(false)
      }
    } catch (error) {
      console.error('Error checking payment status:', error);
      setSuccessMessage(`Error checking payment: ${error.message}`);
      setIsLoading(false)
    }
  };

  const handleCheckout = async () => {
    if (!user || !user.uid) {
      setSuccessMessage('Please log in to proceed with checkout.');
      return;
    }

    try {
      const response = await fetch('/api/stripe-checkout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          amount: total + shippingCost,
          items: basket,
        }),
      });
      const { url } = await response.json();
      window.location = url;
    } catch (error) {
      console.error('Error during checkout:', error);
      setSuccessMessage('An error occurred during checkout. Please try again.');
    }
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    const updatedAddress = { ...shippingAddress, [name]: value };
    setShippingAddress(updatedAddress);
    localStorage.setItem('shippingAddress', JSON.stringify(updatedAddress));
  };
  
  useEffect(() => {
    const savedAddress = localStorage.getItem('shippingAddress');
    if (savedAddress) {
      setShippingAddress(JSON.parse(savedAddress));
    }
  }, []);
  
  const isAddressComplete = () => {
    const values = Object.values(shippingAddress);
    //console.log("Shipping Address Values:", values);
    return values.every(field => field.trim() !== '');
  };
  
  const marge = Number(process.env.REACT_APP_MARGIN);  // Convert the string to a number

  // Adjust the calculation to match the backend
  const unitAmount = Math.round((total + shippingCost) * 100 * (1 + marge) / basket.length);
  const totalFinal = ((unitAmount * basket.length) / 100);

  if (isLoading) {
    return (
      <Background>
        <Content>
          <LoadingMessage>Loading...</LoadingMessage>
        </Content>
      </Background>
    );
  }

  return (
    <>
      <Background>
        <Content>
          <div className="container">
            <h2 className="header">Checkout</h2>

            {user && user.uid ? (
              <>
                <SummaryContainer>
                  <p>Amount: {basket.length} / {maxCards} cards**</p>
                  <p>Printing: {(totalFinal-shippingCost).toFixed(2)} EUR</p>
                  <p>Shipping: {shippingCost.toFixed(2)} EUR</p>
                  <p><strong>Total: {totalFinal.toFixed(2)} EUR</strong></p>
                </SummaryContainer>

                <p>Total Time: 11-17 business days.</p>
                <p>** max no. cards until next pricing tier.</p>
                <br/>
                {isAddressComplete() ? (
                  <ButtonContainer>
                    {totalFinal > 0 && !isNaN(totalFinal) && (
                      <Button onClick={handleCheckout}>Buy</Button>
                    )}
                    <Link to="/gallery"><Button>Cancel</Button></Link>
                  </ButtonContainer>
                ) : (
                  <p>Please complete your shipping address before proceeding to checkout.</p>
                )}

                {successMessage && (
                  <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
                )}

                <FormContainer>
                  First Name*
                  <input
                    type="text"
                    name="firstName"
                    value={shippingAddress.firstName}
                    onChange={handleAddressChange}
                    placeholder="First Name"
                    required
                  />
                  Last Name*
                  <input
                    type="text"
                    name="lastName"
                    value={shippingAddress.lastName}
                    onChange={handleAddressChange}
                    placeholder="Last Name"
                    required
                  />
                  Street Address*
                  <input
                    type="text"
                    name="streetAddress"
                    value={shippingAddress.streetAddress}
                    onChange={handleAddressChange}
                    placeholder="Street Address"
                    required
                  />
                  City*
                  <input
                    type="text"
                    name="city"
                    value={shippingAddress.city}
                    onChange={handleAddressChange}
                    placeholder="City"
                    required
                  />
                  Postal Code*
                  <input
                    type="text"
                    name="postalCode"
                    value={shippingAddress.postalCode}
                    onChange={handleAddressChange}
                    placeholder="Postal Code"
                    required
                  />
                  Country*
                  <select
                    name="country"
                    value={shippingAddress.country}
                    onChange={handleAddressChange}
                    required
                  >
                    <option value="">Select Country</option>
                    {countries.map((country) => (
                      <option key={country} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                </FormContainer>

                <div>
                  <div className={styles.preview_container}>
                    {basket.map((item) => (
                      <LazyLoad
                        key={item.id}
                        height={200}
                        offset={100}
                        className={styles.imageWrapper}
                        placeholder={<div>Loading...</div>}
                      >
                        <img
                          src={`${item.card_id}`}
                          alt={item.card_id}
                          className={styles.image_preview}
                        />
                      </LazyLoad>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <p>Please log in to view your basket and proceed with checkout.</p>
            )}
          </div>
        </Content>
      </Background>
      <Footer />
    </>
  );
};

export default Checkout;





//App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './LandingPage';
import MintingPage from './Minting';
import Eth from './Eth';
import Header from './Header';
import styles from './styles/Header.module.css';
import How2Play from './How2Play';
import Core from './Core';
import Factions from './Factions';
import Roadmap from './Roadmap';
import Rules from './Rules';
import Glitch from './Glitch';
import Gravity from './Gravity';
import Quantum from './Quantum';
import Atomic from './Atomic';
import Planet from './Planet';
import Alchemy from './Alchemy';
import Life from './Life';
import Psycho from './Psycho';
import Crafting from './Crafting';
import About from './About';
import Demo from './Demo';
import Gallery from './Gallery'; 
import Basket from './Basket';
import Checkout from './Checkout';
import Decks from './Decks';
import Decklists from './Decklists';
import Orders from './Orders';
import { auth, db } from './firebaseConfig'; 
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { onAuthStateChanged, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';

function Main() {
  const [isLogged, setIsLogged] = useState(false);
  const [userName, setUserName] = useState(null);
  const [user, setUser] = useState(null);

  const createUserDocument = async (user) => {
    const userRef = doc(db, 'users', user.uid);
    const docSnap = await getDoc(userRef);
    if (!docSnap.exists()) {
      try {
        await setDoc(userRef, { 
          uid: user.uid, 
          email: user.email, 
          creations: 0
        }, { merge: true });
      } catch (error) {
        console.error("Error creating user document:", error);
      }
    }
  };

  const handleLogin = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        createUserDocument(user); // Create or update user document
      })
      .catch((error) => {
        console.error("Error during sign-in:", error);
      });
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setIsLogged(false);
      setUser(null);
      setUserName('');
    } catch (error) {
      console.error("Error signing out: ", error);
      alert("Failed to log out. Please try again.");
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      setIsLogged(!!firebaseUser);
      setUser(firebaseUser);
      if (firebaseUser) {
        setUserName(firebaseUser.displayName);
      } else {
        setUserName('');
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <Header isLogged={isLogged} handleLogin={handleLogin} userName={userName} handleLogout={handleLogout} />
      <main className={styles.content}>
        <Routes>
          <Route path="/" element={<LandingPage isLogged={isLogged} handleLogin={handleLogin} userName={userName} user={user} />} exact />
          <Route path="/app" element={<MintingPage />} />
          <Route path="/eth" element={<Eth />} />
          <Route path="/craft" element={<Crafting />} />
          <Route path="/about" element={<About />} />
          <Route path="/howto" element={<How2Play />} />
          <Route path="/core" element={<Core />} />
          <Route path="/factions" element={<Factions />} />
          <Route path="/roadmap" element={<Roadmap />} />
          <Route path="/rules" element={<Rules />} />
          <Route path="/gallery" element={<Gallery user={user} />} />
          <Route path="/basket" element={<Basket user={user} />} />
          <Route path="/checkout" element={<Checkout user={user} />} />
          <Route path="/glitch" element={<Glitch />} />
          <Route path="/gravity" element={<Gravity />} />
          <Route path="/quantum" element={<Quantum />} />
          <Route path="/atomic" element={<Atomic />} />
          <Route path="/planet" element={<Planet />} />
          <Route path="/alchemy" element={<Alchemy />} />
          <Route path="/life" element={<Life />} />
          <Route path="/psycho" element={<Psycho />} />
          <Route path="/create" element={<Demo isLogged={isLogged} handleLogin={handleLogin} userName={userName} user={user} />} />
          <Route path="/decks" element={<Decks user={user} userName={userName}/>} />
          <Route path="/orders" element={<Orders user={user}/>} />
          <Route path="/decklists" element={<Decklists user={user}/>} />
        </Routes>
      </main>
    </Router>
  );
}

export default Main;

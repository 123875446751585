import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';
import styles from './styles/Gallery.module.css';
import Footer from './Footer';

const bg = '/bg/bg4.png';

const Background = styled.div`
background-image: url(${bg});
background-size: cover;
background-position: center;
min-height: 100vh;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;

const Content = styled.section`
  text-align: left;
  margin: 20px;
  line-height: 1.2;
  color: var(--primary-text);
  font-size: 18px;

  h1, h2, h3, h4, h5, h6 {}

  ul {
    list-style-type: none;
    padding-left: 20px;
    line-height: 1.5;
  }

  strong {
    font-weight: bold;
  }

  p {
    margin-bottom: 15px;
  }
`;

const SuccessMessageContainer = styled.div`
  background-color: #4caf50; /* Green */
  color: white;
  padding: 15px;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  border-radius: 5px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Button = styled.button`
  background: #DAA520;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #c69518;
  }

  a {
    color: white;
    text-decoration: none;
  }
`;

const DecksContainer = styled.div`
  margin: 20px;
`;

const DecksList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const DeckItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const DeckName = styled.span`
  flex: 1;
  padding-right: 10px;
  font-size: 18px;
`;

const Spacer = styled.div`
  width: 10px; /* Adjust the width as needed */
`;

const fetchDecks = async (userId) => {
  try {
    const response = await fetch(`/api/decks?user_id=${userId}`);
    if (!response.ok) {
      throw new Error(`Error fetching decks: ${response.statusText}`);
    }
    return response.json();
  } catch (error) {
    console.error(error);
    return [];
  }
};


const fetchDeck = async (userId, deckName) => {
  try {
    const response = await fetch(`/api/deck?user_id=${userId}&deck_name=${deckName}`);
    if (!response.ok) {
      throw new Error(`Error fetching deck: ${response.statusText}`);
    }
    return response.json();
  } catch (error) {
    console.error(error);
    return null;
  }
};

const deleteDeck = async (userId, deckName) => {
  try {
    const response = await fetch('/api/deck_delete', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user_id: userId, deck_name: deckName }),
    });
    if (!response.ok) {
      throw new Error(`Error deleting deck: ${response.statusText}`);
    }
    return response.json();
  } catch (error) {
    console.error(error);
  }
};

const addCardsToBasket = async (userId, cardIds) => {
  console.log(userId);
  console.log(cardIds)
  try {
    const response = await fetch('/api/basket_add', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user_id: userId, card_ids: cardIds }),
    });
    if (!response.ok) {
      throw new Error(`Error adding cards to basket: ${response.statusText}`);
    }
    return response.json();
  } catch (error) {
    console.error(error);
  }
};



const Decks = ({ user, userName }) => {
  const [decks, setDecks] = useState([]);
  const [selectedDeck, setSelectedDeck] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const fetchDecksData = async () => {
      if (user) {
        const decks = await fetchDecks(user.uid);
        setDecks(decks);
      } else {
        console.log('No user found');
      }
    };
  
    fetchDecksData();
  }, [user]);
  
  const handleDeckClick = async (deckName) => {
    const deck = await fetchDeck(user.uid, deckName);
    setSelectedDeck(deck);
  };

  const handleDeleteDeck = async (deckName) => {
    await deleteDeck(user.uid, deckName);
    setDecks(decks.filter(deck => deck.deck_name !== deckName));
  };

  const handleLoadToBasket = async (userId, deck) => {
    if (user) {
      await addCardsToBasket(userId, deck.card_ids);
      setSuccessMessage('Cards added to Basket!');
      setTimeout(() => setSuccessMessage(''), 3000); // Clear message after 3 seconds
    }
  };

  const handlePublishDeck = async (userId, deckName, userName) => {

    console.log('Attempting to publish deck:', deckName);
  
    
  
    try {
  
      const response = await fetch('/api/deck_publish', {
  
        method: 'POST',
  
        headers: {
  
          'Content-Type': 'application/json',
  
        },
  
        body: JSON.stringify({ user_id: userId, deck_name: deckName, user_name: userName }),
  
      });
  
      
  
      // Log the response for debugging
  
      console.log('Response status:', response.status);
  
  
  
      if (!response.ok) {
  
        const errorData = await response.json();
  
        console.error('Error:', errorData.error);
  
        throw new Error(errorData.error || `Error publishing deck: ${response.statusText}`);
  
      }
  
  
  
      const result = await response.json();
  
      console.log('Publish result:', result);
  
  
  
      if (result) {
  
        setSuccessMessage('Deck published successfully!');
  
        console.log('Success message set');
  
        
  
        // Automatically clear success message after 3 seconds
  
        setTimeout(() => setSuccessMessage(''), 3000);
  
      }
  
    } catch (error) {
  
      console.error('Error publishing deck:', error);
  
      setSuccessMessage('Failed to publish deck. Please try again.');
  
      
  
      // Automatically clear error message after 3 seconds
  
      setTimeout(() => setSuccessMessage(''), 3000);
  
    }
  
  };

  const SuccessMessage = ({ message }) => (
    <SuccessMessageContainer>
      {message}
    </SuccessMessageContainer>
  );

  return (
    <>
      <Background>
        <Content>
          <div className="container">
            <h2 className="header">Your Decks 📚</h2>
            {successMessage && <SuccessMessage message={successMessage} />}
            <p>Save your <Link to="/basket" style={{ color: '#DAA520' }}>Basket</Link> selection as a deck list to buy or share later!</p>
            <div>
              {selectedDeck ? (
                <div>
                  <h1 className="title">{selectedDeck.deck_name}</h1>
                  <ButtonContainer>
                    <Button onClick={() => setSelectedDeck(null)}>Back to Decks</Button>
                    <Button onClick={() => handleLoadToBasket(user.uid,selectedDeck)}>Add to Basket</Button>
                    <Button onClick={() => handlePublishDeck(user.uid,selectedDeck.deck_name)}>Publish Deck</Button>
                  </ButtonContainer>
                  <div className={styles.galleryContainer}>
                    <div className={styles.imageGrid}>
                      {selectedDeck.card_ids.map((card_id, index) => (
                        <LazyLoad
                          key={index}
                          height={200}
                          offset={100}
                          className={styles.imageWrapper}
                          placeholder={<div>Loading...</div>}
                        >
                          <img
                            src={`${card_id}`}
                            alt={card_id}
                            className={styles.image}
                          />
                        </LazyLoad>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <DecksContainer>
                  <h1>Your Saved Decks</h1>
                  <DecksList>
                    {decks.map((deck) => (
                      <DeckItem key={deck.deck_name}>
                        <DeckName>{deck.deck_name}</DeckName>
                        <ButtonContainer>
                          <Spacer />
                          <Button onClick={() => handleDeckClick(deck.deck_name)}>Open</Button>
                          <Spacer />
                          <Button onClick={() => handleDeleteDeck(deck.deck_name)}>Delete</Button>
                          <Spacer />
                          <Button onClick={() => handlePublishDeck(user.uid,deck.deck_name,userName)}>Publish</Button>
                        </ButtonContainer>
                      </DeckItem>
                    ))}
                  </DecksList>
                </DecksContainer>
              )}
            </div>
          </div>
        </Content>
      </Background>
      <Footer />
    </>
  );
};

export default Decks;
import React from 'react';
import { FaTwitter, FaYoutube, FaDiscord, FaInstagram, FaMedium, FaTiktok, FaSteam, FaDice } from 'react-icons/fa';
import { SiOpensea } from 'react-icons/si';
import styled from 'styled-components';
import styles from './styles/Header.module.css'; 
import { Link } from 'react-router-dom';

const SocialButton = styled.a`
  background-color: transparent;
  border: none;
  color: white;
  font-size: 24px;
  margin: 5px;
  cursor: pointer;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  &:hover {
    color: var(--secondary);
  }
`;

const Socials = styled.div`
  display: flex;
  flex-direction: row;
`;

const FooterWrapper = styled.footer`
  background-color: black;
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 50px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: row;
  }
`;

const SocialsMobile = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 769px) {
    display: none;
  }
`;

const SocialsDesktop = styled.div`
  @media (max-width: 769px) {
    display: none;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const ColumnTitle = styled.h2`
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 2.0rem;
  @media (max-width: 1024px) {
    display: None; 
  }
`;

const ColumnLink = styled.a`
  color: white;
  text-decoration: none;
  margin-bottom: 10px;
  @media (max-width: 1024px) {
    display: None; 
  }
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <SocialsMobile>
        <Column>
          <ColumnTitle>Links</ColumnTitle>
          <Socials>
            <SocialButton href="https://twitter.com/eigendark" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </SocialButton>
            <SocialButton href="https://www.youtube.com/@Eigendark" target="_blank" rel="noopener noreferrer">
              <FaYoutube />
            </SocialButton>
            <SocialButton href="https://discord.com/invite/S7tqApMQPm" target="_blank" rel="noopener noreferrer">
              <FaDiscord />
            </SocialButton>
            <SocialButton href="https://www.instagram.com/eigendark" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </SocialButton>
            <SocialButton href="https://steamcommunity.com/sharedfiles/filedetails/?id=3142011637" target="_blank" rel="noopener noreferrer">
              <FaSteam />
            </SocialButton>
            <SocialButton href="https://tabletopia.com/games/eigendark-pen8hm/play-now" target="_blank" rel="noopener noreferrer">
              <FaDice />
            </SocialButton>
            <SocialButton href="https://www.tiktok.com/@eigendark" target="_blank" rel="noopener noreferrer">
              <FaTiktok />
            </SocialButton>
            <SocialButton href="https://opensea.io/collection/eigendark" target="_blank" rel="noopener noreferrer">
              <SiOpensea />
            </SocialButton>
            <SocialButton href="https://medium.com/@eigendark" target="_blank" rel="noopener noreferrer">
              <FaMedium />
            </SocialButton>
          </Socials>
        </Column>
      </SocialsMobile>
      <SocialsDesktop>
        <Column>
          <ColumnTitle>Links</ColumnTitle>
          <Socials>
            <SocialButton href="https://twitter.com/eigendark" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </SocialButton>
            <SocialButton href="https://www.youtube.com/@Eigendark" target="_blank" rel="noopener noreferrer">
              <FaYoutube />
            </SocialButton>
            <SocialButton href="https://discord.com/invite/S7tqApMQPm" target="_blank" rel="noopener noreferrer">
              <FaDiscord />
            </SocialButton>
            <SocialButton href="https://www.instagram.com/eigendark" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </SocialButton>
            <SocialButton href="https://steamcommunity.com/sharedfiles/filedetails/?id=3142011637" target="_blank" rel="noopener noreferrer">
              <FaSteam />
            </SocialButton>
            <SocialButton href="https://tabletopia.com/games/eigendark-pen8hm/play-now" target="_blank" rel="noopener noreferrer">
              <FaDice />
            </SocialButton>
            <SocialButton href="https://www.tiktok.com/@eigendark" target="_blank" rel="noopener noreferrer">
              <FaTiktok />
            </SocialButton>
            <SocialButton href="https://opensea.io/collection/eigendark" target="_blank" rel="noopener noreferrer">
              <SiOpensea />
            </SocialButton>
            <SocialButton href="https://medium.com/@eigendark" target="_blank" rel="noopener noreferrer">
              <FaMedium />
            </SocialButton>
          </Socials>
        </Column>
      </SocialsDesktop>
        <Column>
          <ColumnTitle>Cards</ColumnTitle>
          <ColumnLink >
          <Link to="/core" className={styles.link} >Starter Decks</Link>
          </ColumnLink>
          {/*<ColumnLink >
          <Link to="/app" className={styles.link}>Alpha Set</Link>
          </ColumnLink>*/}
          <ColumnLink >
          <Link to="/gallery" className={styles.link}>Community Set</Link>
          </ColumnLink>
          {/*<ColumnLink >
          <Link to="/shipping" className={styles.link} >Shipping</Link>
          </ColumnLink>*/}
        </Column>
        <Column>
          <ColumnTitle>Info</ColumnTitle>
          {/*<ColumnLink >
          <Link to="/info" className={styles.link} >What is Eigendark?</Link>
          </ColumnLink>*/}
          {/*<ColumnLink >
          <Link to="/faq" className={styles.link} >FAQ</Link>
          </ColumnLink>*/}
          <ColumnLink >
          <Link to="/howto" className={styles.link} >How to play</Link>
          </ColumnLink>
          <ColumnLink>
          <Link to="/about" className={styles.link} >About</Link>
          </ColumnLink>
        </Column>
        <Column>
          <ColumnTitle>Custom</ColumnTitle>
          {/*<ColumnLink >
            <Link to="/craft" className={styles.link} >Craft</Link>
          </ColumnLink>*/}
          <ColumnLink href="#">
            <Link to="/create" className={styles.link} >Create</Link>
          </ColumnLink>
          <ColumnLink >
            <Link to="/decks" className={styles.link}>Decklists</Link>
          </ColumnLink>
          {/*
          <ColumnLink>
          <Link to="/roadmap" className={styles.link} >Roadmap</Link>
          </ColumnLink>*/}
        </Column>
    </FooterWrapper>
  );
};

export default Footer;

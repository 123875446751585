import React, { useEffect }  from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Footer from './Footer';
const bg = './bg/bg6.png';

const Background = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.section`
  text-align: left;
  margin: 20px;
  line-height: 1.6;
  max-width: 100%; 

  h1, h2, h3, h4, h5, h6 {
  }

  ul {
    list-style-type: none;
    padding-left: 20px;
  }

  strong {
    font-weight: bold;
  }

  p {
    margin-bottom: 15px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const StyledLogo = styled.img`
  width: 100%;
  @media (min-width: 1025px) {
    display: None;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

const FactionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const FactionImageDesk = styled.img`
  width: 256px;
  margin-right: 15px;
  @media (min-width: 1025px) {
    margin-top: 20px; /* Adjust this value as per your requirements */
  }
  @media (max-width: 1024px) {
    display: none;
  }
`;

const Rules = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Background>
    <div className={"container"}>
    <ContentWrapper>
      <Content>
      <section className={"content"}>
      <Link to="/glitch" ><StyledLogo src={`${process.env.PUBLIC_URL}/glitch/glitch_faction.jpg`} alt="Glitch Magic" /></Link>
      <FactionContainer>
      <Link to="/glitch" ><FactionImageDesk src={`${process.env.PUBLIC_URL}/glitch/glitch_faction.jpg`} alt="Glitch Magic" /></Link>
        <div>
          <h2 className={"header"}>G̵͍͐ḻ̷̍i̸̻͒t̴͇̎c̶̞̔h̵̹̔ ̸̤̏M̵̯̀a̷͙͆g̸̫̔i̵̤̓c̵̟̽: Exploit the Flaws of Reality</h2>
          <p>Enter the world of politics and turbo-capitalism. Endless hierarchies of managers upon infinite back-office in the kafkaesque maze of despair!
            We fund eldritch VCs to build Dyson-Spheres to maximize the GDP of our Shoe-Empire, yes the universe needs more shoes - and feet-people that buy them!
            Exploit the bugs that lurk within the fabric of existence. Find the backdoors out of this world, enter realms you where never meant to see.
            Life's but a game, and its rules are meant to be broken.&nbsp; 
            <Link to="/glitch" >
            Sign
            </Link>
            &nbsp;this contract and be welcomed to the Company.</p>
        </div>
      </FactionContainer>
      <Link to="/gravity" ><StyledLogo src={`${process.env.PUBLIC_URL}/gravity/gravity_faction.jpg`} alt="Gravity Magic" /></Link>
      <FactionContainer>
      <Link to="/gravity" ><FactionImageDesk src={`${process.env.PUBLIC_URL}/gravity/gravity_faction.jpg`} alt="Gravity Magic" /></Link>
        <div>
        <h2 className={"header"}>☉ Gravity Magic: Embrace the Inevitable</h2>
        <p>Welcome, Gravimancer! The laws of physics that we regard as sacred and immutable, are anything but. To the Gravity Adept, space and time are foldable dough. 
          Bend the very fabric of space-time to your will. Like a black-hole devouring its star, you will become master of slow but certain death. 
          Trap enemies in pocket dimensions of fractal geometry, dilate time and haul Quasars at your opposition. Nothing exists forever.
          Venture out into the cold void and become one with the stars.&nbsp;
          <Link to="/gravity" >Join us </Link>&nbsp;and command the most potent force till Heat Death comes for all!</p> 
        </div>
      </FactionContainer>
      <Link to="/quantum" ><StyledLogo src={`${process.env.PUBLIC_URL}/quantum/quantum_faction.jpg`} alt="Quantum Magic" /></Link>
      <FactionContainer>
      <Link to="/quantum" ><FactionImageDesk src={`${process.env.PUBLIC_URL}/quantum/quantum_faction.jpg`} alt="Quantum Magic" /></Link>
        <div>
        <h2 className={"header"}>⚛ Quantum Magic: Unravel a Maze of infinite Possibilities</h2>
        <p>Greetings, traveler of the multiverse! Why live one life, when you can live all of them? 
          Delve into the quantum foam of baseline reality, slip into the loopholes of subatomic dimensions and hop timelines like train tracks. 
          As Quantum-Mage, you are the photon spellslinger of possibilities, a kaleidoscopic storm of quarks defying all causality.
          Virtualize yourself in and out of existence in a dazzling display of color-charged madness.
          We are you, you are us, entangled as one in an infinite wave.&nbsp;<Link to="/quantum" >Come</Link>&nbsp;superposition yourself, for Quantum-Immortality is all but certain!</p>
        </div>
      </FactionContainer>
      <Link to="/atomic" ><StyledLogo src={`${process.env.PUBLIC_URL}/atomic/atomic_faction.jpg`} alt="Atomic Magic" /></Link>
      <FactionContainer>
      <Link to="/atomic" ><FactionImageDesk src={`${process.env.PUBLIC_URL}/atomic/atomic_faction.jpg`} alt="Atomic Magic" /></Link>
        <div>
        <h2 className={"header"}>☢ Atomic Magic: Become Death, Destroyer of Worlds</h2>
        <p>Salutations, valiant warrior! Can you feel the static buzz in the air? Can you hear the glazed sand cracking beneath your boots? 
          If the radiance of a thousand suns were to burst at once into the sky, that would be like the splendor of your might. 
          The holy Trinity of forces beckons to those with an unquenchable thirst for power! 
          Nothing may break your strong-force armor, no one may prevail in the fury of lightning and irradiation.
          Revel in the rush of unending war, for there's energy yearning to be liberated!&nbsp;<Link to="/atomic" >Join</Link>&nbsp;the Legion and march on the Atomic Path.</p>
        </div>
      </FactionContainer>
      <Link to="/planet" ><StyledLogo src={`${process.env.PUBLIC_URL}/planet/planet_faction.jpg`} alt="Planet Magic" /></Link>
      <FactionContainer>
      <Link to="/planet" ><FactionImageDesk src={`${process.env.PUBLIC_URL}/planet/planet_faction.jpg`} alt="Planet Magic" /></Link>
        <div>
        <h2 className={"header"}>🌐 Planetary Magic: Become One with the Elements</h2>
        <p>Hail to thee, Gaia's champion! Feel the ground beneath your feet, the gas storms crackling above and the waves crashing afar. You have chosen this world, and it has chosen you.
          Heed your planet's call! From deep within its molten core to the icy heights of its stratosphere, the planetary layers are yours to command. Draw upon the versatility of ley lines and your mastery over the elements will be unparalleled. 
          Terraform new planets and cleanse the intruders!
          If you seek harmony with the world and are ready to defend it,&nbsp;<Link to="/planet" >answer</Link>&nbsp;the call and become a guardian of your world.</p>
        </div>
      </FactionContainer>
      <Link to="/alchemy" ><StyledLogo src={`${process.env.PUBLIC_URL}/alchemy/alchemy_faction.jpg`} alt="Alchemy Magic" /></Link>
      <FactionContainer>
      <Link to="/alchemy" ><FactionImageDesk src={`${process.env.PUBLIC_URL}/alchemy/alchemy_faction.jpg`} alt="Alchemy Magic" /></Link>
        <div>
        <h2 className={"header"}>⚝ Alchemy: Discover the Power of Transformation</h2>
        <p>Welcome, student of the Arcane Arts! You know that the only true constant is Change, and as the oldest form of Magic, Alchemy is the study of Change.
          The Alchemists in their search for gold discovered many other things of greater value. 
          But secrets ought to be guarded, for knowledge is power. Cook up volatile acids, healing alloys and transmuting elixirs. The battlefield is your lab, and once all ingredients are in place, you will become unstoppable.
          If you are ready to prove intellect and cunning, you may&nbsp;<Link to="/alchemy" >join</Link>&nbsp;the House of Alchemists and shape the world in your image.</p>
        </div>
      </FactionContainer>
      <Link to="/life" ><StyledLogo src={`${process.env.PUBLIC_URL}/life/life_faction.jpg`} alt="Life Magic" /></Link>
      <FactionContainer>
      <Link to="/life" ><FactionImageDesk src={`${process.env.PUBLIC_URL}/life/life_faction.jpg`} alt="Life Magic" /></Link>
        <div>
          <h2 className={"header"}>☣️ Life Magic: Succumb to Eternal Hunger</h2>
        <p>Bio- and Necromancers, assimilate the Inanimate! Stirring, growing, feasting, nothing quells your thirst.
          Death and decay are but enzymatic tools in your genetic arsenal: the Code of Life is robust and mutant. 
          Spread your viruses like wings, infect your enemies with fungal spores and raise them as undead abominations.
          Life Mages imbibe this essence to overcome any adversity, for it is not the strongest that survives, nor the most intelligent - it is the one most adaptable to change.
          Immortality awaits if you dare shed your mortal shell.&nbsp;<Link to="/life" >Be consumed</Link>&nbsp;by the Hive and become Master over Life and Death!</p>
        </div>
      </FactionContainer>
      <Link to="/psycho" ><StyledLogo src={`${process.env.PUBLIC_URL}/psycho/psycho_faction.jpg`} alt="Psycho Magic" /></Link>
      <FactionContainer>
      <Link to="/psycho" ><FactionImageDesk src={`${process.env.PUBLIC_URL}/psycho/psycho_faction.jpg`} alt="Psycho Magic" /></Link>
        <div>
        <h2 className={"header"}>👁 Psychic Magic: Tap into the Paranormal</h2>
        <p>You are expected, walker of the inner realms! The lunatics have whispered your name in their dreams, your influence like a shadow in their minds.
          You are a savant telepath, projecting your spirit into the subconscious of intelligences, natural and artifical alike. 
          You clutch around their brains like spider legs, weaving and washing until there is only you. Play with their hopes, their fears, for their thoughts are yours to command.
          Relish in their souls to fuel esoteric power.&nbsp;<Link to="/psycho" >Come home</Link>&nbsp;to the House of the Psychic.</p>
       
        </div>
      </FactionContainer>
       </section>
      </Content>
      </ContentWrapper>
    </div>

    </Background>
    <Footer />
    </>
  );
};

export default Rules;


import React, { useEffect }  from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as s from "./styles/globalStyles";
import Footer from './Footer';
import './styles/content.css';

const bg = './bg/bg_li2.png';

const Background = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.section`
  text-align: left;
  margin: 20px;
  line-height: 1.2;
  color: var(--primary-text);
  font-size: 18px;

  h1, h2, h3, h4, h5, h6 {
  }

  ul {
    list-style-type: none;
    padding-left: 20px;
    line-height: 1.5;
  }

  strong {
    font-weight: bold;
  }

  p {
    margin-bottom: 15px;
  }
`;

const InfoPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Background>
    <div className={"container"}>
      <Content>
      <h2 className={"header"}>Try the Demo</h2>
      <p>Play in Browser:&nbsp;<a href="https://tabletopia.com/games/eigendark-pen8hm/play-now" target="_blank" rel="noopener noreferrer" style={{ color: '#DAA520' }}>here</a>.</p>
      <p>Play on Steam:&nbsp;<a href="https://steamcommunity.com/sharedfiles/filedetails/?id=3142011637" target="_blank" rel="noopener noreferrer" style={{ color: '#DAA520' }}>here</a>.</p>
      <s.SpacerMedium />
      <h2 className={"header"}>1. Download the Rules</h2>
      <p>Download the rules to Eigendark here: <a href="https://drive.google.com/uc?export=open&id=1URW_7IgPPyDLnYM-0H6uROweUHfx6IqG" style={{ color: '#DAA520' }}>Rules.pdf</a></p>
      <s.SpacerMedium />
      <h2 className={"header"}>2. Get your cards</h2>
      <p>I. Choose your <Link to="/factions" style={{ color: '#DAA520' }}>Sciences!</Link></p>
      <p>II. Get your&nbsp;<Link to="/core" style={{ color: '#DAA520' }}>Starter Deck!</Link></p>
      <p>III. Pick cards from the&nbsp;<Link to="/gallery" style={{ color: '#DAA520' }}>Gallery!</Link></p>
      <p>IV. Choose a curated&nbsp;<Link to="/decklists" style={{ color: '#DAA520' }}>Decklist!</Link></p>
      {/*<p>IV. Buy booster packs from the&nbsp;<a href="https://www.ebay.com/itm/134861629582" target="_blank" rel="noopener noreferrer" style={{ color: '#DAA520' }}>Ethereum Set</a></p>*/}
      {/* <s.SpacerSmall />
      <p>How to mint NFTs:</p>
      <ul> 1: Get at least 11 MATIC (ca. $5 USD, via <a href="https://coinbase.com" target="_blank">Coinbase</a>, <a href="https://binance.com" target="_blank">Binance</a> or other exchange)</ul>
      <ul> 2: <a href="https://metamask.io/" target="_blank">Install</a> Metamask</ul>
      <ul> 3: <a href="https://www.coingecko.com/learn/how-to-add-polygon-network-to-metamask" target="_blank">Add</a> Polygon network</ul>
      <ul> 4: Send the MATIC to your Metamask wallet address</ul>
      <ul> 5: Click "Connect" and make sure your network is set to Polygon</ul>
      <s.SpacerSmall />*/}
      <s.SpacerMedium />
      <h2 className={"header"}>3. Play Formats</h2>
      <p>Standard: decks must contain cards from any one year (+ Core Set).</p>
      <p>Zodiac: decks must contain cards from the same Zodiac  (+ Core Set).</p>
      <p>Limited: decks must contain cards from the same set/author (pref. yourself).</p>
      <p>Wild: no deck building restrictions.</p>
      <s.SpacerMedium />
      <h2 className={"header"}>4. Make your own!</h2>
      <p>Create&nbsp;<Link to="/create" style={{ color: '#DAA520' }} >custom cards</Link></p>
      {/*<p>II. Mint&nbsp;<Link to="/eth" style={{ color: '#DAA520' }}>digital cards</Link></p>
       <p>III. Craft&nbsp;<Link to="/craft" style={{ color: '#DAA520' }}>new cards</Link></p>*/}
      </Content>
    </div>
    </Background>
    <Footer />
    </>
  );
};

export default InfoPage;

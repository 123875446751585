if (process.env.NODE_ENV !== 'production') {
  require('dotenv').config({ path: '.env.local' });
}

import React, { useState, useEffect } from 'react';
import LazyLoad from 'react-lazyload';
import { useNavigate, Link } from 'react-router-dom';
import styled from 'styled-components';
import styles from './styles/Gallery.module.css';
import Footer from './Footer';

const Background = styled.div`
  background: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.section`
  text-align: center;
  margin: 20px;
  line-height: 1.2;
  color: var(--primary-text);
  font-size: 18px;

  h1, h2, h3, h4, h5, h6 {}

  ul {
    list-style-type: none;
    padding-left: 20px;
    line-height: 1.5;
  }

  strong {
    font-weight: bold;
  }

  p {
    margin-bottom: 15px;
  }
`;

const Tabs = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const TabButton = styled.button`
  background: ${(props) => (props.active ? '#DAA520' : '#333')};
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #DAA520;
  }
`;

const SearchContainer = styled.div`
  margin-bottom: 20px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const SearchInput = styled.input`
  width: ${(props) => (props.fullWidth ? '100%' : 'calc(33% - 10px)')};
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const SearchButton = styled.button`
  background: #DAA520;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #c69518;
  }
`;

const SuccessMessageContainer = styled.div`
  background-color: #4caf50; /* Green */
  color: white;
  padding: 15px;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  border-radius: 5px;
`;

const Spacer = styled.div`
  width: 10px; 
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px; 
  align-items: center; 
`;

const extractCardSetAndId = (key) => {
  const parts = key.split('/');
  const fileName = parts[parts.length - 1];
  const fileNameWithoutExtension = fileName.split('.')[0]; // Remove file extension
  const splitName = fileNameWithoutExtension.split('_');
  const cardSet = splitName.slice(0, -1).join('_'); // Join all parts except the last one

  return { cardSet };
};


const addToBasket = async (userId, cardIds) => {
  await fetch('/api/basket_add', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ user_id: userId, card_ids: cardIds }),
  });
};


const SuccessMessage = ({ message }) => (
  <SuccessMessageContainer>
    {message}
    <Link to="/basket" style={{ color: 'white', textDecoration: 'underline', marginLeft: '10px' }}>
      Go to Basket
    </Link>
  </SuccessMessageContainer>
);

const Gallery = ({ user }) => {
  const [images, setImages] = useState([]);
  const [query, setQuery] = useState('');
  const [hp, setHp] = useState('');
  const [ap, setAp] = useState('');
  const [charges, setCharges] = useState('');
  const [cost, setCost] = useState('');
  const [year, setYear] = useState('');
  const [cardSet, setCardSet] = useState('');
  const [activeTab, setActiveTab] = useState('basic');
  const [error, setError] = useState(null);
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [color, setColor] = useState('');
  const [race, setRace] = useState('');
  const [supertype, setSupertype] = useState('');
  const [zodiac, setZodiac] = useState('');
  const [cardClass, setCardClass] = useState('');
  const [keyword, setKeyword] = useState('');
  const [modifier, setModifier] = useState('');
  const [selectedCards, setSelectedCards] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const handleSearch = async (e) => {
    if (e) e.preventDefault();
    try {
      const params = new URLSearchParams();
      if (activeTab === 'basic' && query) {
        params.append('query', query);
      } else if (activeTab === 'advanced') {
        if (name) params.append('name', name);
        if (type) params.append('type', type);
        if (color) params.append('color', color);
        if (race) params.append('race', race);
        if (supertype) params.append('supertype', supertype);
        if (zodiac) params.append('zodiac', zodiac);
        if (cardSet) params.append('card_set', cardSet);
        if (cardClass) params.append('card_class', cardClass);
        if (keyword) params.append('keyword', keyword);
        if (modifier) params.append('modifier', modifier);
        if (hp) params.append('hp', hp);
        if (ap) params.append('ap', ap);
        if (charges) params.append('charges', charges);
        if (cost) params.append('cost', cost);
        if (year) params.append('year', year);
      }

      const response = await fetch(`/api/search?${params.toString()}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const imagesWithUrls = data.map((card) => {
      const cardSet = card.card_set;
      const cardId = card.card_id;
        if (!cardSet) {
          console.error(`Missing card_set or card_id for card:`, card);
        }
        return {
          ...card,
          url: encodeURI(card.image_url),
          uniqueId: `${cardSet}_${cardId}` // Ensuring unique composite ID
        };
      });
      setImages(imagesWithUrls.sort((a, b) => new Date(b.LastModified) - new Date(a.LastModified)));
    } catch (error) {
      setError(error.message);
      console.error('Error fetching search results:', error);
    }
  };
  

  const fetchImages = async () => {
    try {
      const response = await fetch('/api/images');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const fetchedImages = await response.json();
      const validImages = fetchedImages.filter((image) => image.Size > 0);
      const imagesWithUrls = validImages.map((image) => {
        const { cardSet } = extractCardSetAndId(image.Key);
        if (!cardSet ) {
          console.error(`Missing card_set or card_id for image:`, image);
        }
        return {
          ...image,
          url: `${process.env.REACT_APP_S3_BUCKET_URL}/${encodeURIComponent(image.Key)}`,
          uniqueId: `${cardSet}` // Ensuring unique composite ID
        };
      });
      setImages(imagesWithUrls.sort((a, b) => new Date(b.LastModified) - new Date(a.LastModified)));
    } catch (error) {
      setError(error.message);
      console.error('Error fetching images:', error);
    }
  };  
  

  useEffect(() => {
    fetchImages();
  }, []);

  const handleCardSelect = (uniqueId) => {
    if (!uniqueId || uniqueId === 'undefined-undefined') {
      console.error('Invalid Card ID:', uniqueId);
      return;
    }
    setSelectedCards((prevSelectedCards) =>
      prevSelectedCards.includes(uniqueId)
        ? prevSelectedCards.filter((selectedId) => selectedId !== uniqueId)
        : [...prevSelectedCards, uniqueId]
    );
  };

  const handleAddToBasket = async () => {
    if (user) {
      const selectedCardsUrls = images.filter(image => selectedCards.includes(image.uniqueId)).map(image => image.url);
      await addToBasket(user.uid, selectedCardsUrls);
      setSuccessMessage('Cards have been added!');
      setTimeout(() => setSuccessMessage(''), 3000); // Clear message after 3 seconds
    }
  };
  

  return (
    <>
      <Background>
        <Content>
          <div className="container">
            <h2 className="header">Our Community Collection</h2>
            <p style={{ textAlign: 'center' }}>
              Click the cards you like and add them to your basket to save or purchase! <br/> 
              Make your own cards <Link to="/create" style={{ color: '#DAA520' }}>here.</Link>
              {/*n&nbsp;<a href="https://www.ebay.com/itm/134861624112" target="_blank" rel="noopener noreferrer" style={{ color: '#DAA520' }}>eBAY</a>*/}
            </p>
            {/*<p>600+ custom cards made by over 30 unique creators!</p>*/}
            <SearchContainer>
              <Tabs>
                <TabButton 
                  active={activeTab === 'basic'} 
                  onClick={() => setActiveTab('basic')}
                >
                  Basic Search
                </TabButton>
                <TabButton 
                  active={activeTab === 'advanced'} 
                  onClick={() => setActiveTab('advanced')}
                >
                  Advanced
                </TabButton>
              </Tabs>
              <form onSubmit={handleSearch}>
                {activeTab === 'basic' && (
                  <SearchInput
                    type="text"
                    placeholder="Search cards..."
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    fullWidth
                  />
                )}
                {activeTab === 'advanced' && (
                  <FormGroup>
                    <SearchInput
                      type="text"
                      placeholder="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <SearchInput
                      type="text"
                      placeholder="Type"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    />
                    <SearchInput
                      type="text"
                      placeholder="Science"
                      value={color}
                      onChange={(e) => setColor(e.target.value)}
                    />
                    <SearchInput
                      type="text"
                      placeholder="Species"
                      value={race}
                      onChange={(e) => setRace(e.target.value)}
                    />
                    <SearchInput
                      type="text"
                      placeholder="Supertype"
                      value={supertype}
                      onChange={(e) => setSupertype(e.target.value)}
                    />
                    <SearchInput
                      type="text"
                      placeholder="Zodiac"
                      value={zodiac}
                      onChange={(e) => setZodiac(e.target.value)}
                    />
                    <SearchInput
                      type="text"
                      placeholder="Author"
                      value={cardSet}
                      onChange={(e) => setCardSet(e.target.value)}
                    />
                    <SearchInput
                      type="text"
                      placeholder="Class"
                      value={cardClass}
                      onChange={(e) => setCardClass(e.target.value)}
                    />
                    <SearchInput
                      type="text"
                      placeholder="Keyword"
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value)}
                    />
                    <SearchInput
                      type="text"
                      placeholder="Modifier"
                      value={modifier}
                      onChange={(e) => setModifier(e.target.value)}
                    />
                    <SearchInput
                      type="number"
                      placeholder="HP"
                      value={hp}
                      onChange={(e) => setHp(e.target.value)}
                    />
                    <SearchInput
                      type="number"
                      placeholder="AP"
                      value={ap}
                      onChange={(e) => setAp(e.target.value)}
                    />
                    <SearchInput
                      type="number"
                      placeholder="Charges"
                      value={charges}
                      onChange={(e) => setCharges(e.target.value)}
                    />
                    <SearchInput
                      type="number"
                      placeholder="Cost"
                      value={cost}
                      onChange={(e) => setCost(e.target.value)}
                    />
                    <SearchInput
                      type="number"
                      placeholder="Year"
                      value={year}
                      onChange={(e) => setYear(e.target.value)}
                    />
                  </FormGroup>
                )}
                <ButtonContainer>
                  <SearchButton type="submit">Search Cards</SearchButton>
                  <Spacer />
                  <SearchButton onClick={handleAddToBasket} disabled={selectedCards.length === 0}>Add to Basket</SearchButton>
                  {successMessage && <SuccessMessage message={successMessage} />}
                  <Spacer />
                  <SearchButton onClick={fetchImages} >Reset filter</SearchButton>
                </ButtonContainer>
              </form>
            </SearchContainer>
            {error && <p className="error">{error}</p>}
            <div className={styles.galleryContainer}>
              <div className={styles.imageGrid}>
                {images.map((image, index) => {
                  return (
                    <LazyLoad key={index} height={200} offset={100} className={styles.imageWrapper} placeholder={<div>Loading...</div>}>
                      <img 
                        src={image.url} 
                        alt={image.name} 
                        className={styles.image}
                        onClick={() => handleCardSelect(image.uniqueId)}
                        style={{ border: selectedCards.includes(image.uniqueId) ? '2px solid gold' : 'none' }}
                        onError={(e) => console.error(`Error loading image: ${image.url}`, e)}
                      />
                    </LazyLoad>
                  );
                })}
              </div>
            </div>
          </div>
        </Content>
      </Background>
      <Footer />
    </>
  );
};

export default Gallery;

import React, { useEffect } from 'react';
import styled from 'styled-components';
import Footer from './Footer';
const bg = './bg/bg_ps.png';
import { Link } from 'react-router-dom';

const Background = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.section`
  text-align: left;
  margin: 20px;
  line-height: 1.6;
  max-width: 100%; 

  h1, h2, h3, h4, h5, h6 {
  }

  ul {
    list-style-type: none;
    padding-left: 20px;
  }

  strong {
    font-weight: bold;
  }

  p {
    margin-bottom: 15px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const StyledLogo = styled.img`
  max-width: 256px;
  @media (min-width: 1025px) {
    display: None;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

const FactionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const FactionImageDesk = styled.img`
  max-width: 256px;
  margin-right: 15px;
  @media (min-width: 1025px) {
    margin-top: 10px; /* Adjust this value as per your requirements */
  }
  @media (max-width: 1024px) {
    display: none;
  }
`;
const Psycho = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Background>
    <div className={"container"}>
    <ContentWrapper>
      <Content>
      <section className={"content"}>
      <StyledLogo src={`${process.env.PUBLIC_URL}/psycho/psycho_faction0.jpg`} alt="Psycho Magic" />
      <FactionContainer>
      <FactionImageDesk src={`${process.env.PUBLIC_URL}/psycho/psycho_faction0.jpg`} alt="Psycho Magic" />
        <div>
          <h2 className={"header"}>Psycho Magic 👁</h2>
          <p>The Spirit School is a mind maze where the boundary between sanity and madness blurs, opening up an intriguing world of abstract ideas, emotions, and dreams. Delve into the deepest recesses of your psyche and connect with the emotional tapestry of other beings. Here, adepts walk a delicate tightrope between lucidity and lunacy, mastering the potent arts of psychokinesis, telepathy, and mind control to transform into Psycho Mages, some of the most formidable adversaries to encounter.
            The Psycho Mage's unique playstyle revolves around turning their foe's resources against them and silencing their fancy plans. 
          </p>
        </div>
      </FactionContainer>
      <StyledLogo src={`${process.env.PUBLIC_URL}/psycho/psycho_faction1.jpg`} alt="Psycho Magic" />
      <FactionContainer>
      <FactionImageDesk src={`${process.env.PUBLIC_URL}/psycho/psycho_faction1.jpg`} alt="Psycho Magic" />
        <div>
          <p>
          Meet the Psycho Savants, the scholars of the inner mind, their gaze intense, hinting at the depth of their mental prowess. Many of them were individuals dealing with the human psyche in their past lives, their unique experiences providing unparalleled insights and perspectives into the realm of the mind.
          Psycho Mages come from all walks of life – they can be artists, monks, psychologists, neuroscientists, philosophers and even social workers. Each, in their own way, transcends their earthly nature to become one with the limbo beyond the sea of dreams. Except for the nihilistic Clownfolk, they dress simply, allowing nothing to distract from their mental focus, appearing as enlightened seers in a blend of cybernetic enhancements and mystic robes.
          </p>
        </div>
      </FactionContainer>
      <StyledLogo src={`${process.env.PUBLIC_URL}/psycho/psycho_faction2.jpg`} alt="Psycho Magic" />
      <FactionContainer>
      <FactionImageDesk src={`${process.env.PUBLIC_URL}/psycho/psycho_faction2.jpg`} alt="Psycho Magic" />
        <div>
          <p>
          Venture deeper and you'll meet the Dream Weavers, an obscure niche within the Psychic Abyss. These dwellers of the subconscious realm have honed the ability to navigate and manipulate the dreamscape. They weave intricate illusions and bring forth nightmares from the darkest corners of the mind.
          <br />
          Are you intrigued by the prospect giving your opponent a taste of their own medicine? If so, the Psycho deck is the perfect choice for you. The cards within this deck grant you the power to control and disrupt your opponents' actions, offering a unique gameplay experience. The card art features abstract representations of eldritch mindscapes, appealing to fans of surreal art and Lovecraftian horror. So, enter this realm of mystery and power. Your journey towards mastering the soul begins now.
          <br />
          Get the &nbsp;<Link to="/core" >starter deck</Link>&nbsp;or&nbsp;<Link to="/app" >mint new cards</Link>!
          </p>
        </div>
      </FactionContainer>
       </section>
      </Content>
      </ContentWrapper>
    </div>
    </Background>
    <Footer />
    </>
  );
};

export default Psycho;


import React, { useEffect }  from 'react';
import styled from 'styled-components';
import * as s from "./styles/globalStyles";
import Footer from './Footer';
import { Link } from 'react-router-dom';
import './styles/content.css';

const bg = '/bg/bg3.png';

const Background = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.section`
  text-align: left;
  margin: 20px;
  line-height: 1.2;
  color: var(--primary-text);
  font-size: 18px;

  h1, h2, h3, h4, h5, h6 {
  }

  ul {
    list-style-type: none;
    padding-left: 20px;
    line-height: 1.5;
  }

  strong {
    font-weight: bold;
  }

  p {
    margin-bottom: 15px;
  }
`;

const InfoPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Background>
    <div className={"container"}>
      <Content>
      <h2 className={"header"}>About Eigendark</h2>
      <p>Eigendark is a trading card game with collective card creation and open world building. It allows you to translate your ideas into functional trading cards. The&nbsp;<Link to="/create" style={{ color: '#DAA520' }}>Card Creator</Link> gives you the opportunity to design entirely new cards.</p>
      <p>The game is meant to be played as multiplayer at the kitchen table, but it can also be played 1v1 (and any flat surface). An online game with digital cards may or may not come out depending on player demand, but you can always play online using TTS on Steam.</p>
      <p>The lore is kept intentionally vague to become a canvas for YOUR stories and characters. You are a Prompt-Wizard, with the power to devise new spells from an ancient Shoggoth-Tongue. You find yourself in a liminal time, a dream in latent space perhaps, where science has become indistinguishable from magic. Beseech gods of artificial intelligence to earn their wacky gifts.&nbsp;<Link to="/core" style={{ color: '#DAA520' }}>Pick your Science</Link> and venture out into realms that where never meant to be!</p>
      </Content>
    </div>
    </Background>
    <Footer />
    </>
  );
};

export default InfoPage;

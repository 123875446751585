import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import styles from './styles/Header.module.css';
{/*import { connect } from "./redux/blockchain/blockchainActions";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";*/}
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faBookmark, faList } from '@fortawesome/free-solid-svg-icons';
import styled from "styled-components";

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: 2px solid transparent;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  display: flex; 
  justify-content: center;
  text-align: center;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  outline: none;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  @media (max-width: 768px) {
    font-size: 12px; 
    width: 80px;
  }
`;

const Header = ({ isLogged, handleLogin, userName, handleLogout}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const [active, setActive] = useState(false);
  const [expandedSection, setExpandedSection] = useState(null);
  const toggleSection = (section) => {
    if (expandedSection === section) {
      setExpandedSection(null);
    } else {
      setExpandedSection(section);
    }
  };
  const toggleSidebar = () => {
    setActive(!active);
  };

  return (
    <>
      <header className={styles.header}>
        <div className={styles.headerContent}>
          <button className={styles.button} onClick={toggleSidebar}>
            {active ? '×' : '☰'}
          </button>
          <Link to="/">
            {isMobile ? (
              <span className={styles.logo}>🏠</span> // Replace with a house emoji or icon
            ) : (
              <img src="/logo.png" alt="Home" className={styles.logo} />
            )}
          </Link>
          <nav className={styles.desktopNav}>
            <div className={styles.navItem}>
              <span className={styles.link}>
                <Link to="/core" className={styles.link}>Cards</Link>
              </span>
            </div>
            <div className={styles.navItem}>
              <span className={styles.link}>
                <Link to="/howto" className={styles.link}>Info</Link>
              </span>
            </div>
            <div className={styles.navItem}>
              <span className={styles.link}>
                <Link to="/create" className={styles.link}>Create</Link>
              </span>
            </div>
            <div className={styles.navItem}>
              <span className={styles.link}>
                <Link to="/gallery" className={styles.link}>Gallery</Link>
              </span>
            </div>
            <div className={styles.navItem}>
              <span className={styles.link}>
                <Link to="/decklists" className={styles.link}>Decklists</Link>
              </span>
            </div>
          </nav>
        </div>
        <div className={styles.iconMenu}>
                <Link to="/basket" className={styles.iconLink}>
                  <FontAwesomeIcon icon={faShoppingCart} size="lg" color="gold" />
                </Link>
                <Link to="/decks" className={styles.iconLink}>
                  <FontAwesomeIcon icon={faBookmark} size="lg" color="gold" />
                </Link>
                <Link to="/orders" className={styles.iconLink}>
                  <FontAwesomeIcon icon={faList} size="lg" color="gold" />
                </Link>
        </div>
        {isLogged ? (
          userName ? (
            <div className={styles.userMenu}>

              <StyledButton
                className={styles.loginButton}
                onClick={handleDropdownToggle}
              >
                {userName}
              </StyledButton>
              {isDropdownOpen && (
                <div className={styles.dropdownMenu} ref={dropdownRef}>
                <Link to="/basket" className={styles.dropdownItem} onClick={() => setIsDropdownOpen(false)}>
                  <span className={styles.icon}>🛒</span> Basket
                </Link>
                <Link to="/decks" className={styles.dropdownItem} onClick={() => setIsDropdownOpen(false)}>
                  <span className={styles.icon}>📚</span> Decks
                </Link>
                <Link to="/orders" className={styles.dropdownItem} onClick={() => setIsDropdownOpen(false)}>
                  <span className={styles.icon}>📦</span> Orders
                </Link>
                <Link className={styles.dropdownItem} onClick={handleLogout}>
                  <span className={styles.icon}>⏻</span> Logout
              </Link> 
              </div>
              )}
            </div>
          ) : (
            <StyledButton className={styles.loginButton}>Anon</StyledButton>
          )
        ) : (
          <StyledButton className={styles.loginButton} onClick={handleLogin}>LOGIN</StyledButton>
        )}


      </header>

      <div className={`${styles.sidebar} ${active ? styles.active : ''}`}>
        <h1 onClick={() => toggleSection('cards')}>
          Cards {expandedSection === 'cards' ? '▼' : '▶'}
        </h1>
        {expandedSection === 'cards' && (
          <>
            <Link to="/core" className={`${styles.link} ${styles.dropdownItem}`} onClick={toggleSidebar}>
              Starters
            </Link>
            <Link to="/gallery" className={`${styles.link} ${styles.dropdownItem}`} onClick={toggleSidebar}>
              Gallery
            </Link>
            <Link to="/decklists" className={`${styles.link} ${styles.dropdownItem}`} onClick={toggleSidebar}>
              Decklists
            </Link>
          </>
        )}
        <h1 onClick={() => toggleSection('play')}>
          Info {expandedSection === 'play' ? '▼' : '▶'}
        </h1>
        {expandedSection === 'play' && (
          <>
            <Link to="/howto" className={`${styles.link} ${styles.dropdownItem}`} onClick={toggleSidebar}>
              How to play
            </Link>
            <Link to="/factions" className={`${styles.link} ${styles.dropdownItem}`} onClick={toggleSidebar}>
              Factions
            </Link>
            <Link to="/about" className={`${styles.link} ${styles.dropdownItem}`} onClick={toggleSidebar}>
              About
            </Link>
          </>
        )}
        <h1 onClick={() => toggleSection('utility')}>
          Custom {expandedSection === 'utility' ? '▼' : '▶'}
        </h1>
        {expandedSection === 'utility' && (
          <>
            <Link to="/create" className={`${styles.link} ${styles.dropdownItem}`} onClick={toggleSidebar}>
              Create
            </Link>
          </>
        )}
      </div>
    </>
  );
};

export default Header;

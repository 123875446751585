import React, { useEffect } from 'react';
import styled from 'styled-components';
import Footer from './Footer';
const bg = './bg/bg_pl2.png';
import { Link } from 'react-router-dom';

const Background = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.section`
  text-align: left;
  margin: 20px;
  line-height: 1.6;
  max-width: 100%; 

  h1, h2, h3, h4, h5, h6 {
  }

  ul {
    list-style-type: none;
    padding-left: 20px;
  }

  strong {
    font-weight: bold;
  }

  p {
    margin-bottom: 15px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const StyledLogo = styled.img`
  max-width: 256px;
  @media (min-width: 1025px) {
    display: None;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

const FactionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const FactionImageDesk = styled.img`
  max-width: 256px;
  margin-right: 15px;
  @media (min-width: 1025px) {
    margin-top: 10px; /* Adjust this value as per your requirements */
  }
  @media (max-width: 1024px) {
    display: none;
  }
`;

const Planet = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Background>
    <div className={"container"}>
    <ContentWrapper>
      <Content>
      <section className={"content"}>
      <StyledLogo src={`${process.env.PUBLIC_URL}/planet/planet_faction0.jpg`} alt="Planet Magic" />
      <FactionContainer>
      <FactionImageDesk src={`${process.env.PUBLIC_URL}/planet/planet_faction0.jpg`} alt="Planet Magic" />
        <div>
          <h2 className={"header"}>Planet Magic 🌐 </h2>
          <p>As a Planet Mage, you are more than a mere inhabitant of these terrestrial bodies. You are a guardian and a ruler. The planet's life force flows within you, its deep ley line veins intertwining with your own. And with such power, you can evoke fiery volcanic eruptions, summon colossal tidal waves, or manipulate the furious winds of a storm. Your tactical versatility is as immense and dynamic as the planet's surface itself. To challenge you is to defy the planet beneath.
            The Planetary playstyle is a balanced midrange to lategame. 
          </p>
        </div>
      </FactionContainer>
      <StyledLogo src={`${process.env.PUBLIC_URL}/planet/planet_faction1.jpg`} alt="Planet Magic" />
      <FactionContainer>
      <FactionImageDesk src={`${process.env.PUBLIC_URL}/planet/planet_faction1.jpg`} alt="Planet Magic" />
        <div>
          <p>
          When you wear the Steampunk armor of a Planet Mage, humming with the energy infused from various ecosystems, you embrace your planet's spirit. Your AI familiar, a formidable elemental beast, embodies the raw force of nature you command. Together, you unleash the planet's fury on the battlefield, turning the terrain into a living weapon.
          <br />
          Beyond the battlefield, Planet Mages strive for a universe in harmony, a symphony of ecosystems where humanity and nature coexist sustainably. They're not just conquerors but caretakers, masters of terraforming, who shape and nurture planets to support life. They infuse symbiosis into urban landscapes, ensuring a harmonious blend of technology and nature.
          </p>
        </div>
      </FactionContainer>
      <StyledLogo src={`${process.env.PUBLIC_URL}/planet/planet_faction2.jpg`} alt="Planet Magic" />
      <FactionContainer>
      <FactionImageDesk src={`${process.env.PUBLIC_URL}/planet/planet_faction2.jpg`} alt="Planet Magic" />
        <div>
          <p>
          The Gaia spirit attracts people from all walks of life, but especially geologists, meteorologists, farmers and environmentalists. As a Planet Whisperer, your garb reflects the environment you harness - whether it's the dense weave of the jungle, the endless sands of a desert, or the icy chill of a tundra. This connection goes beyond the understanding of geological processes. It's about the instinctive, spiritual bond you share with your environment.
          <br />
          The Planet deck embodies this variety, providing a balanced set of offensive and defensive cards that adapt to ever-changing game conditions. Its richness and adaptability make it an excellent choice for players who enjoy diverse strategies and appreciate the intricate details of nature and Earth Science.        
          <br />
          Get the &nbsp;<Link to="/core" >starter deck</Link>&nbsp;or&nbsp;<Link to="/app" >mint new cards</Link>!
          </p>
        </div>
      </FactionContainer>
       </section>
      </Content>
      </ContentWrapper>
    </div>
    </Background>
    <Footer />
    </>
  );
};

export default Planet;

